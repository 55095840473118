import { FC, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import {
  indexPath,
  User,
} from 'libs/apiClient/admin/users';
import { get } from 'libs/apiClient';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import UserCreateFormInsideCampaign from './UserCreateForm/UserCreateFormInsideCampaign';
import UserCreateFormOutsideCampaign from './UserCreateForm/UserCreateFormOutsideCampaign';
import UserCreateFormDoNotCall from './UserCreateForm/UserCreateFormDoNotCall';
import UserUpdateForm from './UserUpdateForm';
import Entry from '../entries';
import Schedule from '../schedules';
import Toast from 'components/Toast';

const Users: FC = () => {
  const [isCreateFormTypeModalOpen, setIsCreateFormTypeModalOpen] = useState<boolean>(false)
  const [createFormType, setCreateFormType] = useState<string>('insideCampaign');
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>();
  const [users, setUsers] = useState<User[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string | number | null>(null);
  const [isDisplayToast, setIsDisplayToast] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleCreateFormOpen = () => {
    setIsCreateFormOpen(true)
    setUser(null)
    setIsCreateFormTypeModalOpen(false)
  }

  const onSearch = () => {
    const callback = (res: User[]) => {
      setUsers(res);
      if (res.length == 0) {
        setMessage('該当するユーザーは見つかりませんでした');
        setIsDisplayToast(true);
      }
    }

    get(indexPath(searchKeyword), 'users', callback)
  }

  const handleUpdate = (user: User) => {
    setIsCreateFormOpen(false)
    setIsCreateFormTypeModalOpen(false)
    setUser(user)
  }

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title='ユーザー検索'>
          <Button variant="primary" onClick={_e => setIsCreateFormTypeModalOpen(true)}>新規作成</Button>
        </PanelTitle>
        <Form className='col-6'>
          <Form.Group controlId='userSearch'>
            <Form.Label>メールアドレス or 電話番号</Form.Label>
            <Form.Control
              type='text'
              name="userSearch"
              onChange={e => setSearchKeyword(e.target.value)}
            />
          </Form.Group>
          <Button type="button" variant='outline-primary' onClick={onSearch}>検索</Button>
        </Form>
      </Panel>

      <Panel className='pb-4'>
        <PanelTitle title='検索結果' />
        <Table className='mb-2'>
          <thead>
            <tr>
              <th>ID</th>
              <th>姓</th>
              <th>名</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(user =>
              <tr key={user.userId}>
                <td className={`align-middle`} >{user.userId}</td>
                <td className={`align-middle`} >{user.lastName}</td>
                <td className={`align-middle`} >{user.firstName}</td>
                <td className={`align-middle`} >{user.email}</td>
                <td className={`align-middle`} >{user.phoneNumber}</td>
                <td className={`align-middle`} >
                  <Button
                    variant='dark'
                    onClick={() => handleUpdate(user)}
                  >
                    詳細
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {user && (
          <>
            <UserUpdateForm key={user.userId} user={user} onClose={() => setUser(null)} />
            <Entry userId={String(user.userId)}/>
            <Schedule uuid={user.uuid} userId={user.userId} />
          </>
        )}
      </Panel>
      {(isCreateFormOpen && createFormType === 'insideCampaign') &&
        <UserCreateFormInsideCampaign
          onClose={() => setIsCreateFormOpen(false)}
        />
      }
      {(isCreateFormOpen && createFormType === 'outsideCampaign') &&
        <UserCreateFormOutsideCampaign
          onClose={() => setIsCreateFormOpen(false)}
        />
      }
      {(isCreateFormOpen && createFormType === 'doNotCall') &&
        <UserCreateFormDoNotCall
          onClose={() => setIsCreateFormOpen(false)}
        />
      }

      <Modal show={isCreateFormTypeModalOpen} onHide={() => setIsCreateFormTypeModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>フォームタイプ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='createFormType'>
            <Form.Label>登録フォームを選択してください</Form.Label>
            <>
              <Form.Control
                as="select"
                custom
                onChange={ e => {setCreateFormType(e.target.value)} }
              >
                <option value='insideCampaign'>イベント登録</option>
                <option value='outsideCampaign'>媒体登録</option>
                <option value='doNotCall'>電話停止登録</option>
              </Form.Control>
            </>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsCreateFormTypeModalOpen(false)}>
            閉じる
          </Button>
          <Button variant="primary" onClick={() => handleCreateFormOpen()}>
            作成
          </Button>
        </Modal.Footer>
      </Modal>

      {isDisplayToast &&
        <Toast
          message={message}
          isDisplayToast={isDisplayToast}
          setIsDisplayToast={setIsDisplayToast}
        />
      }
    </>
  )
}

export default Users;
