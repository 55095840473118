import { FC, useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { updatePath, RequestData, Errors, User, SpecialityClassification } from 'libs/apiClient/admin/users';
import { Prefecture } from 'libs/apiClient/prefectures';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import { useFetchPrefectures } from 'hooks/api/prefectures/useFetchPrefectures';
import { update } from 'libs/apiClient';
import { useUniversityOptions } from 'hooks/useUniversityOptions';
import { useUniversityFacultyOptions } from 'hooks/useUniversityFacultyOptions';
import { useUniversityDepartmentOptions } from 'hooks/useUniversityDepartmentOptions';
import { ErrorToast } from 'components/ErrorToast';
import DateTime from 'components/DateTime';

type FormProps = {
  user: User;
  onClose: () => void;
}

const GENDER_MAP = {
  '男性': 'man',
  '女性': 'woman',
  'その他': 'other',
}

const SPECIALITY_CLASSIFICATION_MAP = {
  '文系': 'arts',
  '理系': 'sciences',
  'その他': 'others'
}

type FormData = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefectureId: number | null;
  gender: string;
  specialityClassification: SpecialityClassification;
  universityId: string;
  universityFacultyId: string;
  universityDepartmentId: string;
  admissionOn: string;
  graduateOn: string;
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export const UpdateForm: FC<FormProps> = ({ user, onClose }) => {
  const [formData, setFormData] = useState<FormData>({
    firstName: user.firstName,
    lastName: user.lastName,
    firstNameKana: user.firstNameKana,
    lastNameKana: user.lastNameKana,
    email: user.email,
    phoneNumber: user.phoneNumber,
    graduateYear: user.graduateYear,
    graduateMonth: 3,
    prefectureId: user.prefecture.id,
    gender: GENDER_MAP[user.gender],
    specialityClassification: SPECIALITY_CLASSIFICATION_MAP[user.specialityClassification] as SpecialityClassification,
    universityId: user.universitySet?.universityId,
    universityFacultyId: user.universitySet?.universityFacultyId,
    universityDepartmentId: user.universitySet?.universityDepartmentId,
    admissionOn: user.universitySet?.admissionOn,
    graduateOn: user.universitySet?.graduateOn,
    jobHuntingStatus: user.jobHuntingStatus,
    isEmailAvailable: user.isEmailAvailable,
    isPhoneAvailable: user.isPhoneAvailable,
  })

  const [errors, setErrors] = useState<Errors>({});
  const { prefectures } = useFetchPrefectures();
  const thisYear = new Date().getFullYear();
  const yearOptions = [0, 1, 2, 3, 4, 5].map(n => {
    const year = thisYear + n
    return {
      id: n + 1,
      label: `${year}年`,
      value: year,
      isDisplay: true,
      isSelected: false
    }
  })
  const [degree, setDegree] = useState<string | undefined>(user.universitySet?.degree)
  const [searchKey, setSearchKey] = useState<string | undefined>(user.universitySet?.searchKey)
  const { reloadUniversityOptions, universityOptions } = useUniversityOptions();
  const { reloadUniversityFacultyOptions, universityFacultyOptions } = useUniversityFacultyOptions();
  const { reloadUniversityDepartmentOptions, universityDepartmentOptions } = useUniversityDepartmentOptions();
  const [isDisplayErrorToast, setIsDisplayErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string|null>(null);

  const onChange = (value: FormData[keyof FormData], key: keyof FormData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const handleSubmit = () => {
    const callback = (_res: unknown) => {
      onClose()
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    const body: RequestData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      firstNameKana: formData.firstNameKana,
      lastNameKana: formData.lastNameKana,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      graduateYear: Number(formData.graduateYear),
      graduateMonth: 3,
      prefectureId: formData.prefectureId,
      gender: formData.gender,
      specialityClassification: formData.specialityClassification,
      universitySet: {
        id: user.universitySet?.id,
        universityId: formData.universityId || '',
        universityFacultyId: formData.universityFacultyId || '',
        universityDepartmentId: formData.universityDepartmentId || '',
        admissionOn: formData.admissionOn,
        graduateOn: formData.graduateOn
      },
      jobHuntingStatus: formData.jobHuntingStatus,
      isEmailAvailable: formData.isEmailAvailable,
      isPhoneAvailable: formData.isPhoneAvailable,
    }

    const serverErrCallback = (errMessage: string) => {
      setErrorMessage(errMessage)
      setIsDisplayErrorToast(true);
    }

    update(updatePath(user.uuid), { user: body }, 'user', callback, errCallback, undefined, serverErrCallback);
  }

  const onChangeKey = (searchKey?: string, degree?: string) => {
    setSearchKey(searchKey)
    setDegree(degree)

    if (searchKey && degree) {
      reloadUniversityOptions(searchKey, degree)
      setFormData({
        ...formData,
        universityId: '',
        universityFacultyId: '',
        universityDepartmentId: ''
      })
    }
  }

  useEffect(() => {
    if (searchKey && degree) {
      reloadUniversityOptions(searchKey, degree)
    }
  }, [])

  useEffect(() => {
    if (formData.universityId) {
      reloadUniversityFacultyOptions(formData.universityId)
    }
  }, [formData.universityId])

  useEffect(() => {
    if (formData.universityFacultyId) {
      reloadUniversityDepartmentOptions(formData.universityFacultyId)
    }
  }, [formData.universityFacultyId])

  return (
    <>
      <Panel className='pb-4'>
        <PanelTitle title={`ユーザー編集(ユーザーID: ${user.userId})`} />
        <Form noValidate className='col-6'>
          <Form.Group controlId='graduationYear'>
            <Form.Label>卒年</Form.Label>
            <Form.Control
              as="select"
              custom
              name='graduateYear'
              value={formData.graduateYear || ''}
              onChange={e => { onChange(e.target.value, 'graduateYear') }}
              isInvalid={errors.graduateYear && errors.graduateYear?.length > 0}
            >
              <option></option>
              {yearOptions.map(option =>
                <option key={`graduationYear-${option.id}`} value={option.value}>
                  {option.label}
                </option>
              )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.graduateYear?.map((err, i) => <p key={`error-graduateYear-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Form.Group as={Col} md="6" controlId='lastName'>
              <Form.Label>姓</Form.Label>
              <Form.Control
                type='text'
                name="lastName"
                value={formData.lastName}
                onChange={e => onChange(e.target.value, 'lastName')}
                placeholder={'姓を入力してください'}
                isInvalid={errors.lastName && errors.lastName?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.map((err, i) => <p key={`error-lastName-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId='firstName'>
              <Form.Label>名</Form.Label>
              <Form.Control
                type='text'
                name="firstName"
                value={formData.firstName}
                onChange={e => onChange(e.target.value, 'firstName')}
                placeholder={'名を入力してください'}
                isInvalid={errors.firstName && errors.firstName?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.map((err, i) => <p key={`error-firstName-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="6" controlId='lastNameKana'>
              <Form.Label>セイ</Form.Label>
              <Form.Control
                type='text'
                name="lastNameKana"
                value={formData.lastNameKana}
                onChange={e => onChange(e.target.value, 'lastNameKana')}
                placeholder={'セイを入力してください'}
                isInvalid={errors.lastNameKana && errors.lastNameKana?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastNameKana?.map((err, i) => <p key={`error-lastNameKana-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId='firstNameKana'>
              <Form.Label>メイ</Form.Label>
              <Form.Control
                type='text'
                name="firstNameKana"
                value={formData.firstNameKana}
                onChange={e => onChange(e.target.value, 'firstNameKana')}
                placeholder={'メイを入力してください'}
                isInvalid={errors.firstNameKana && errors.firstNameKana?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstNameKana?.map((err, i) => <p key={`error-firstNameKana-${i}`}>{err}</p>)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group controlId='phoneNumber'>
            <Form.Label>電話番号</Form.Label>
            <Form.Control
              type='text'
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={e => onChange(e.target.value, 'phoneNumber')}
              placeholder={'09012345678'}
              isInvalid={errors.phoneNumber && errors.phoneNumber?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber?.map((err, i) => <p key={`error-phoneNumber-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label>メールアドレス</Form.Label>
            <Form.Control
              type='text'
              name="email"
              value={formData.email}
              onChange={e => onChange(e.target.value, 'email')}
              placeholder={'shutatsu-times@gmail.com'}
              isInvalid={errors.email && errors.email?.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.map((err, i) => <p key={`error-email-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='prefectureId'>
            <Form.Label>お住いの都道府県</Form.Label>
            <Form.Control
              as="select"
              custom
              name='prefecture'
              value={formData.prefectureId || ''}
              onChange={e => { onChange(e.target.value, 'prefectureId') }}
              isInvalid={errors.prefectureId && errors.prefectureId?.length > 0}
            >
              <option></option>
              {prefectures.map((prefecture: Prefecture) => (
                <option key={`prefecture-${prefecture.id}`} value={prefecture.id}>
                  {prefecture.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.prefectureId?.map((err, i) => <p key={`error-prefectureId-${i}`}>{err}</p>)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='gender'>
            <Form.Label>性別</Form.Label>
            <div className={``}>
              <Form.Check
                inline
                id='man'
                type={'radio'}
                name='gender'
                label='男性'
                checked={formData.gender === 'man'}
                onChange={_e => onChange('man', 'gender')}
              />
              <Form.Check
                inline
                id='woman'
                type={'radio'}
                name='gender'
                label='女性'
                checked={formData.gender === 'woman'}
                onChange={_e => onChange('woman', 'gender')}
              />
              <Form.Check
                inline
                id='other'
                type={'radio'}
                name='gender'
                label='その他'
                checked={formData.gender === 'other'}
                onChange={_e => onChange('other', 'gender')}
              />
            </div>
          </Form.Group>

          <Form.Group controlId='shooleSpecialityGroups'>
            <Form.Label>文理</Form.Label>
            <div>
              <Form.Check
                inline
                type={'radio'}
                name='specialityClassification'
                label='文系'
                checked={formData.specialityClassification === 'arts'}
                onChange={_e => onChange('arts', 'specialityClassification')}
              />
              <Form.Check
                inline
                type={'radio'}
                name='specialityClassification'
                label='理系'
                checked={formData.specialityClassification === 'sciences'}
                onChange={_e => onChange('sciences', 'specialityClassification')}
              />
              <Form.Check
                inline
                type={'radio'}
                name='specialityClassification'
                label='その他'
                checked={formData.specialityClassification === 'others'}
                onChange={_e => onChange('others', 'specialityClassification')}
              />
            </div>
          </Form.Group>

          <Form.Group controlId='degree'>
            <Form.Label>最終学歴</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={e => onChangeKey(searchKey, e.target.value)}
              value={degree}
            >
              <option></option>
              <option value='undergraduate'>大学卒業見込み</option>
              <option value='master'>大学院(修士)卒業見込み</option>
              <option value='doctor'>大学院(博士)卒業見込み</option>
              <option value='junior_college'>短大卒業見込み</option>
              <option value='vocational_school'>専門学校卒業見込み</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='searchKey'>
            <Form.Label>学校検索</Form.Label>
            <Form.Control
              as="select"
              custom
              value={searchKey}
              onChange={e => onChangeKey(e.target.value, degree)}
            >
              <option></option>
              <option value="あ">あ</option>
              <option value="い">い</option>
              <option value="う">う</option>
              <option value="え">え</option>
              <option value="お">お</option>
              <option value="か">か</option>
              <option value="き">き</option>
              <option value="く">く</option>
              <option value="け">け</option>
              <option value="こ">こ</option>
              <option value="さ">さ</option>
              <option value="し">し</option>
              <option value="す">す</option>
              <option value="せ">せ</option>
              <option value="そ">そ</option>
              <option value="た">た</option>
              <option value="ち">ち</option>
              <option value="つ">つ</option>
              <option value="て">て</option>
              <option value="と">と</option>
              <option value="な">な</option>
              <option value="に">に</option>
              <option value="の">の</option>
              <option value="は">は</option>
              <option value="ひ">ひ</option>
              <option value="ふ">ふ</option>
              <option value="へ">へ</option>
              <option value="ほ">ほ</option>
              <option value="ま">ま</option>
              <option value="み">み</option>
              <option value="む">む</option>
              <option value="め">め</option>
              <option value="も">も</option>
              <option value="や">や</option>
              <option value="よ">よ</option>
              <option value="ら">ら</option>
              <option value="り">り</option>
              <option value="る">る</option>
              <option value="れ">れ</option>
              <option value="わ">わ</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='name'>
            <Form.Label>学校名</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={e => { onChange(e.target.value, 'universityId') }}
              value={formData.universityId}
            >
              <option></option>
              {universityOptions.map((universityOption) =>
                <option
                  key={`schoolName-${universityOption.id}`}
                  value={universityOption.value}
                >
                  {universityOption.label}
                </option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='faculty'>
            <Form.Label>学部名</Form.Label>
            <Form.Control
              as="select"
              custom
              value={formData.universityFacultyId}
              onChange={e => { onChange(e.target.value, 'universityFacultyId') }}
            >
              <option></option>
              {universityFacultyOptions.map((facultyOption) =>
                <option
                  key={`schoolFaculty-${facultyOption.id}`}
                  value={facultyOption.value}
                >
                  {facultyOption.label}
                </option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='department'>
            <Form.Label>学科名</Form.Label>
            <Form.Control
              as="select"
              custom
              value={formData.universityDepartmentId}
              onChange={e => { onChange(e.target.value, 'universityDepartmentId') }}
            >
              <option></option>
              {universityDepartmentOptions.map((departmentOptions) =>
                <option
                  key={`schoolDepartment-${departmentOptions.id}`}
                  value={departmentOptions.value}
                >
                  {departmentOptions.label}
                </option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>入学年</Form.Label>
            <DateTime
              format='YYYY/MM/DD'
              onChange={ value => onChange(value, "admissionOn") }
              value={ formData.admissionOn || '' }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>卒業年</Form.Label>
            <DateTime
              format='YYYY/MM/DD'
              onChange={ value => onChange(value, "graduateOn") }
              value={ formData.graduateOn }
            />
          </Form.Group>


          <Form.Group controlId='jobHuntingStatus'>
            <Form.Label>就活ステータス</Form.Label>
            <Form.Control
              as="select"
              custom
              value={formData.jobHuntingStatus}
              onChange={e => { onChange(e.target.value, 'jobHuntingStatus') }}
            >
              <option value='active'>就活中</option>
              <option value='inactive'>就活終了</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='isPhoneAvailable'>
            <Form.Check
              key={'is-phone-available'}
              inline
              id={'isPhoneAvailable'}
              type={'checkbox'}
              name='isPhoneAvailable'
              label={'電話停止'}
              checked={!formData.isPhoneAvailable}
              onChange={e => onChange(!e.target.checked, 'isPhoneAvailable')}
            />
          </Form.Group>

          <Form.Group controlId='isEmailAvailable'>
            <Form.Check
              key={'is-email-available'}
              inline
              id={'isEmailAvailable'}
              type={'checkbox'}
              name='isEmailAvailable'
              label={'メール配信停止'}
              checked={!formData.isEmailAvailable}
              onChange={e => onChange(!e.target.checked, 'isEmailAvailable')}
            />
          </Form.Group>

          <Form.Group>
            <Button type="button" className='mr-2' variant="outline-secondary" onClick={onClose}>キャンセル</Button>
            <Button type="button" variant='outline-success' onClick={handleSubmit}>保存</Button>
          </Form.Group>
        </Form>
      </Panel>

      { isDisplayErrorToast && (
        <ErrorToast
          message={errorMessage}
          isDisplayToast={isDisplayErrorToast}
          setIsDisplayToast={setIsDisplayErrorToast}
        />
      )}
    </>
  )
}
