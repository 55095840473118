import { Prefecture } from 'libs/apiClient/prefectures';

export const indexPath = (keyword: string | number | null = null): string => {
  const queryParams = keyword ? new URLSearchParams([['keyword', String(keyword)]]) : '';
  return `api/v1/admin/users?${queryParams}`;
}
export const createPath = 'api/v1/admin/users';
export const showPath = (id: string | number) => `api/v1/admin/users/${id}`
export const updatePath = (id: string | number) => `api/v1/admin/users/${id}`

export type SpecialityClassification = 'arts' | 'sciences' | 'others'

export type User = {
  uuid: string;
  userId: number;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefecture: Prefecture;
  gender: '男性' | '女性' | 'その他';
  specialityClassification: '文系' | '理系' | 'その他';
  universitySet: {
    id: string;
    universityId: string;
    universityFacultyId: string;
    universityDepartmentId: string;
    admissionOn: string;
    graduateOn: string;
    degree: string;
    searchKey: string;
  };
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type RequestData = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  phoneNumber: number | string;
  graduateYear: number;
  graduateMonth: number;
  prefectureId: number | null;
  gender: string;
  specialityClassification: SpecialityClassification;
  universitySet?: {
    id?: string;
    universityId: string;
    universityFacultyId: string;
    universityDepartmentId: string;
    admissionOn: string;
    graduateOn: string;
  }
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
}

export type Response = {
  id: number | string;
}

export type Errors = {
  firstName?: string[];
  lastName?: string[];
  firstNameKana?: string[];
  lastNameKana?: string[];
  email?: string[];
  phoneNumber?: string[];
  graduateYear?: string[];
  graduateMonth?: string[];
  prefectureId?: string[];
  gender?: string[];
  specialityClassification?: string[];
}
